<template>
<div>
    <!-- modal -->
    <b-modal id="matterDocumentFormModal" ref="matterDocumentFormModal" centered size="lg" no-close-on-backdrop :title=" $t('matterDocument.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row class="mt-1">
                <b-col>
                    <validation-provider #default="{ errors }" :name=" $t('matterDocument.name')" rules="required">
                        <label for="matter-document-name-input">{{ $t('matterDocument.name') }}</label>
                        <b-input-group>
                            <b-form-input size="sm" id="matter-document-name-input" v-model="documentName" maxlength="50" :state="errors.length > 0 ? false:null" />
                            <b-input-group-append>
                                <b-button size="sm" variant="outline-primary" onclick="document.getElementById('documentsFileSelect').click()">{{ $t('others.select') }}
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <b-form-file size="sm" style="display:none" id="documentsFileSelect" accept="*.*" @change="uploadFile" :placeholder=" $t('others.select_documents')" :drop-placeholder=" $t('others.select_documents_info')" />
            </b-row>
            <b-row class="mt-1">
                <b-col class="col-12">
                    <b-form>
                        <label for="matter-document-type-input">{{ $t('matterDocument.type') }}</label>
                        <b-form-group>
                            <b-form-input id="matter-document-type-input" size="sm" v-model="documentType" maxlength="4" disabled />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col class="col-6">
                    <label for="matter-document-date-input">{{ $t('matterDocument.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                </b-col>
                <b-col class="col-6">
                    <b-form>
                        <label for="matter-document-employee-input"> {{ $t('matterDocument.employee') }} </label>
                        <b-form-group>
                            <validation-provider :name=" $t('matterDocument.employee')" :rules="{ excluded:0}" v-slot="{ errors }">
                                <b-form-select disabled :state="errors.length > 0 ? false:null" v-model="employeeSelect" value-field="id" text-field="name" size="sm" :options="employeeOption" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <b-form>
                        <label for="matter-document-note-input">{{ $t('matterDocument.note') }}</label>
                        <b-form-group>
                            <b-form-textarea id="matter-document-note-input" :placeholder=" $t('matterDocument.note')" v-model="note" rows="3" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getMatterDocumentList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            recordId: 0,
            moduleId: 0,
            documentContentSelect: 0,
            documentContentOption: [],
            documentName: '',
            documentFile: '',
            documentType: '',
            note: '',
            date: null,
            employeeSelect: 0,
            employeeOption: [],
        }
    },
    methods: {
        async uploadFile(e) {
            var fileInfo = await this.$getFileInfo(e.target.files[0])
            this.documentName = fileInfo.name
            this.documentType = fileInfo.contentType
            this.documentFile = fileInfo.base64
        },
        showInfo(id, recordId, moduleId) {
            this.$refs['matterDocumentFormModal'].show()
            this.id = id
            this.recordId = recordId
            this.moduleId = moduleId
            const data = {
                id: this.id,
                moduleId: this.moduleId
            }
            const controller = {
                name: 'LawDocument'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.employeeOption = response.data.employeeList
                    if (response.data.lawDocument.employeeId > 0) {
                        this.employeeSelect = response.data.lawDocument.employeeId
                    } else {
                        this.employeeSelect = localStorage.getItem('EmployeeId')
                    }
                    this.documentName = response.data.lawDocument.name
                    this.note = response.data.lawDocument.note
                    this.documentType = response.data.lawDocument.type
                    this.date = response.data.lawDocument.date
                    this.employeeOption.splice(0, 0, this.$nullSelected("name"))
                } else {
                    this.$bvModal.hide('matterDocumentFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                recordId: this.recordId,
                moduleId: this.moduleId,
                note: this.note,
                name: this.documentName,
                type: this.documentType,
                date: this.date,
                base64: this.documentFile,
                employeeId: this.employeeSelect,

            }
            const controller = {
                name: 'LawDocument'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (this.id == 0 && this.documentFile == '') {
                        this.$WarningAlert(this.$t('alerts.base64_null'))
                    } else {
                        let loader = this.$loading.show()
                        this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('matterDocumentFormModal')
                                this.getMatterDocumentList(this.moduleId, this.recordId)
                                this.$SaveAlert()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                            }
                            loader.hide()
                        })
                    }
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
