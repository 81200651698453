<template>
<div>
    <b-modal id="lawInvoiceUpdateDetailFormModal" centered size="lg" no-close-on-backdrop ref="lawInvoiceUpdateDetailFormModal" :title=" $t('lawInvoiceUpdateDetail.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-col class="col-lg-12">
                <label for="invoice-add-item-description-input">{{ $t('lawInvoiceUpdateDetail.description') }}</label>
                <validation-provider #default="{ errors }" :description=" $t('lawInvoiceUpdateDetail.description')" rules="required">
                    <b-form-textarea id="invoice-add-item-description-input" size="sm" rows="3" maxlength="600" :state="errors.length > 0 ? false:null" v-model="description" />

                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
        </validation-observer>
    </b-modal>

</div>
</template>

<script>
export default {
    props: {
        showLawInvoiceInfo: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            lawInvoiceId: 0,
            description: '',
        }
    },
    methods: {
        showInfo(id, lawInvoiceId) {
            this.lawInvoiceId = lawInvoiceId;
            this.id = id
            this.$refs['lawInvoiceUpdateDetailFormModal'].show()
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawInvoice',
                actionName: 'GetLawInvoiceDetailInfo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.description = response.data.lawInvoiceDetail.name
                } else {
                    this.$bvModal.hide('lawInvoiceUpdateDetailFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })

        },
        addOrUpdate() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const data = {
                        lawDailyworkId: this.id,
                        description: this.description,
                    }
                    const controller = {
                        name: 'LawInvoice',
                        actionName: 'UpdateLawDailyworkAsInvoiceDetail'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('lawInvoiceUpdateDetailFormModal')
                            this.showLawInvoiceInfo(this.lawInvoiceId)
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style>
.tab-content>.active {
    display: block;
    width: 75em;
}
</style>
