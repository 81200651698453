<template>
<div>
    <b-modal id="matterFormModal" ref="matterFormModal" centered size="xl" no-close-on-backdrop :title=" (lawMatterPlaintiffAsString == null)?(recordNo == null)?'':recordNo + ' - ' + shortName + ' ('+ matterType + ')':recordNo + ' - ' + shortName + ' ('+ matterType + ')'+  '-'+ $t('matter.matter_plaintiff')+':'+ lawMatterPlaintiffAsString" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" hide-footer>
        <b-card>            
            <b-row>
                <b-col>
                    <label class="text-danger" v-if="lawClientPowerOfAttorneyDeadlineDate != null">{{ $t('lawClient.power_of_attorney_deadline_date') + ':' + $formatFn(lawClientPowerOfAttorneyDeadlineDate) }}</label>
                </b-col>
                <b-col>
                    <div class="custom-search d-flex justify-content-end">
                        <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="addOrUpdate">
                            {{ $t('others.ok_title') }}
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col class="col-lg-9">
                        <b-form>
                            <label for="matter-name-input">{{ $t('matter.name') }}</label>
                            <b-form-group>
                                <b-form-input id="matter-name-input" size="sm" v-model="name" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>

                    <b-col class="col-lg-3">
                        <b-form>
                            <label for="matter-name-input">{{ $t('matter.record_date') }}</label>
                            <b-form-group>
                                <b-form-input id="matter-name-input" size="sm" v-model="recordDate" disabled />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="matterTypeSelect == 1" class="col-lg-3">
                        <b-form>
                            <label for="matter-sub-type-input"> {{ $t('matter.matter_sub_type') }} </label>
                            <b-form-group>
                                <validation-provider :name=" $t('matter.matter_sub_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                                    <b-form-select :state="errors.length > 0 ? false:null" v-model="matterSubTypeSelect" value-field="id" text-field="name" size="sm" :options="matterSubTypeOption" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col v-if="matterTypeSelect == 1" class="col-md-3">
                        <b-form>
                            <label for="matter-case-type-input">{{ $t('matter.matter_case_type') }}</label>
                            <b-form-select size="sm" v-model="lawMatterCaseTypeSelect" value-field="id" text-field="name" :options="lawMatterCaseTypeOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="matter-status-input">{{ $t('matter.matter_status') }}</label>
                            <b-form-select size="sm" v-model="matterStatusSelect" value-field="id" text-field="name" :options="matterStatusOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="matter-file-type-input">{{ $t('matter.matter_file_type') }}</label>
                            <b-form-select size="sm" v-model="matterFileTypeSelect" value-field="id" text-field="name" :options="matterFileTypeOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-6">
                        <b-form>
                            <label for="matter-short-name-input">{{ $t('matter.short_name') }}</label>
                            <b-form-group>
                                <b-form-input id="matter-short-name-input" size="sm" v-model="shortName" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <label for="matter-operation-record-no-input">{{ $t('matter.operation_record_no') }}</label>
                            <b-form-group>
                                <b-form-input id="matter-operation-record-no-input" size="sm" v-model="operationRecordNo" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-md-3">
                        <b-form>
                            <validation-provider #default="{ errors }" :name=" $t('matter.agent_ref_no')" rules="required">
                                <label for="matter-agent-reference-no-input">{{ $t('matter.agent_ref_no') }}</label>
                                <b-form-group>
                                    <b-form-input id="matter-agent-reference-no-input" size="sm" v-model="agentReferenceNo" maxlength="50" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-4">
                        <b-form>
                            <label for="matter-type-input"> {{ $t('matter.matter_type') }} </label>
                            <b-form-group>
                                <validation-provider :name=" $t('matter.matter_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                                    <b-form-select :disabled="id > 0" :state="errors.length > 0 ? false:null" v-model="matterTypeSelect" value-field="id" text-field="name" size="sm" :options="matterTypeOption" @change="changeTabStatus()" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-form>
                    </b-col>

                    <b-col class="col-md-4">
                        <b-form>
                            <label for="matter-first-lawyer-input">{{ $t('matter.first_lawyer') }}</label>
                            <b-form-select size="sm" v-model="firstLawyerSelect" value-field="id" text-field="name" :options="firstLawyerOption" />
                        </b-form>
                    </b-col>
                    <b-col class="col-md-4">
                        <b-form>
                            <label for="matter-second-lawyer-input">{{ $t('matter.second_lawyer') }}</label>
                            <b-form-select size="sm" v-model="secondLawyerSelect" value-field="id" text-field="name" :options="secondLawyerOption" />
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-4">
                        <b-form>
                            <validation-provider :name="$t('matter.client')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <b-form-group :label="$t('matter.client')" label-for="matter-client-input" :state="errors.length > 0 ? false:null">
                                    <v-select class="select-size-sm" id="matter-agent-input" v-model="matterClientSelect" :options="matterClientOption" @input="setMatterClientId" label="name" @search="onSearch"></v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null"> {{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <validation-provider :name="$t('matter.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <b-form-group :label="$t('matter.agent')" label-for="matter-agent-input" :state="errors.length > 0 ? false : null">
                                    <v-select class="select-size-sm" id="matter-agent-input" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onAgentSearch"></v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <validation-provider :name="$t('matter.invoice_agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                <b-form-group :label="$t('matter.invoice_agent')" label-for="matter-invoice-agent-input" :state="errors.length > 0 ? false : null">
                                    <v-select class="select-size-sm" id="matter-invoice-agent-input" v-model="invoiceAgentSelect" :options="invoiceAgentOption" @input="setClientId" label="clientName" @search="onInvoiceAgentSearch"></v-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-card>

        <b-card :title="$t('matter.matter_info')" :hidden="hiddenMatterInfo">
            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-info-subject-input">{{ $t('matterInfo.subject') }}</label>
                        <b-form-group>
                            <b-form-select id="matter-info-subject-input" size="sm" v-model="matterInfoSubjectSelect" value-field="id" text-field="name" :options="matterInfoSubjectOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-info-court-input">{{ $t('matterInfo.court') }}</label>
                        <b-form-group>
                            <b-form-select id="matter-info-court-input" size="sm" v-model="matterInfoCourtSelect" value-field="id" text-field="name" :options="matterInfoCourtOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-info-case-number-input">{{ $t('matterInfo.case_number') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-info-case-number-input" v-model="matterInfoCaseNumber" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-info-filed-on-date-input">{{ $t('matterInfo.filed_on_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterInfo.filed_on_date')" size="sm" v-model="matterInfoFiledOnDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </b-card>

        <b-card :title="$t('matter.matter_consulting')" :hidden="hiddenMatterConsulting">
            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-consulting-opposite-side-input">{{ $t('matterConsulting.opposite_side') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-consulting-opposite-side-input" v-model="matterConsultingOppositeSide" maxlength="300" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-matter-outcome-input">{{ $t('matterConsulting.matter_outcome') }}</label>
                        <b-form-group>
                            <b-form-select id="matter-matter-outcome-input" size="sm" v-model="matterConsultingMatterOutcomeSelect" value-field="id" text-field="name" :options="matterConsultingMatterOutcomeOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-consulting-c-d-letter-sent-date-input">{{ $t('matterConsulting.c_d_letter_sent_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterConsulting.c_d_letter_sent_date')" size="sm" v-model="matterConsultingCdLetterSentDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-consulting-c-d-letter-served-input">{{ $t('matterConsulting.c_d_letter_served_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterConsulting.c_d_letter_served_date')" size="sm" v-model="matterConsultingCdLetterServedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="col-lg-6">
                    <b-form>
                        <label for="matter-consulting-received-date-input">{{ $t('matterConsulting.received_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterConsulting.received_date')" size="sm" v-model="matterConsultingReceivedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </b-card>

        <b-card :title="$t('matter.matter_customs_registration')" :hidden="hiddenMatterCustomsRegistration">
            <b-row>
                <b-col class="col-lg-8">
                    <b-form>
                        <label for="matter-customs-registration-trademark-input">{{ $t('matterCustomsRegistration.trademark') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-customs-registration-trademark-input" v-model="matterCustomsRegistrationTrademarkName" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-customs-registration-conclusion-input">{{ $t('matterCustomsRegistration.conclusion') }}</label>
                        <b-form-group>
                            <b-form-select id="matter-customs-registration-conclusion-input" size="sm" v-model="matterCustomsRegistrationConclusionSelect" value-field="id" text-field="name" :options="matterCustomsRegistrationConclusionOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-customs-registration-number-input">{{ $t('matterCustomsRegistration.number') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-customs-registration-number-input" v-model="matterCustomsRegistrationNumber" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-customs-registration-first-application-date-input">{{ $t('matterCustomsRegistration.first_application_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCustomsRegistration.first_application_date')" size="sm" v-model="matterCustomsRegistrationFirstApplicationDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-customs-registration-application-number-input">{{ $t('matterCustomsRegistration.application_number') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-customs-registration-application-number-input" v-model="matterCustomsRegistrationApplicationNo" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-customs-registration-last-application-date-input">{{ $t('matterCustomsRegistration.last_application_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCustomsRegistration.last_application_date')" size="sm" v-model="matterCustomsRegistrationApplicationDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-customs-registration-response-date-input">{{ $t('matterCustomsRegistration.response_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCustomsRegistration.response_date')" size="sm" v-model="matterCustomsRegistrationResponseDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-customs-registration-expiration-date-input">{{ $t('matterCustomsRegistration.expiration_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCustomsRegistration.expiration_date')" size="sm" v-model="matterCustomsRegistrationExpirationDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-renewal-reminder-is-sent-input">{{ $t('matterCustomsRegistration.is_sent') }}</label>
                        <b-form-group>
                            <b-form-checkbox class="custom-control-success" name="matter-renewal-reminder-is-sent-input" id="matter-renewal-reminder-is-sent-input" v-model="lawMatterCustomsRegistrationIsRenewalInstructionBeenSent" switch>
                                <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                </span>
                            </b-form-checkbox>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col v-if="lawMatterCustomsRegistrationIsRenewalInstructionBeenSent == true" class="col-lg-4">
                    <b-form>
                        <label for="matter-customs-registration-date-input">{{ $t('matterCustomsRegistration.date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterCustomsRegistration.date')" size="sm" v-model="lawMatterCustomsRegistrationRenewalReminderDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form>
                        <label for="matter--is-there-renewal-instruction-input">{{ $t('matterCustomsRegistration.is_there_renewal_instruction') }}</label>
                        <b-form-group>
                            <b-form-checkbox class="custom-control-success" name="matter--is-there-renewal-instruction-input" id="matter--is-there-renewal-instruction-input" v-model="lawMatterCustomsRegistrationIsThereARenewalInstruction" switch>
                                <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                </span>
                            </b-form-checkbox>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </b-card>

        <b-card :title="$t('matter.matter_stop_customs')" :hidden="hiddenMatterStopCustoms">
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-office-input">{{ $t('matterStopCustoms.office') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-stop-customs-office-input" v-model="matterStopCustomsOffice" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-decision-no-input">{{ $t('matterStopCustoms.decision_no') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-stop-customs-decision-no-input" v-model="matterStopCustomsDecisionNo" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-decision-date-input">{{ $t('matterStopCustoms.decision_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterStopCustoms.decision_date')" size="sm" v-model="matterStopCustomsDecisionDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-opposite-side-input">{{ $t('matterStopCustoms.opposite_side') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-stop-customs-opposite-side-input" v-model="matterStopCustomsOppositeSide" maxlength="300" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-sender-input">{{ $t('matterStopCustoms.sender') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-stop-customs-sender-input" v-model="matterStopCustomsSender" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-bearer-input">{{ $t('matterStopCustoms.bearer') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-stop-customs-bearer-input" v-model="matterStopCustomsBearer" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-type-of-shipment-input">{{ $t('matterStopCustoms.type_of_shipment') }}</label>
                        <b-form-group>
                            <b-form-select id="matter-stop-customs-type-of-shipment-input" size="sm" v-model="matterStopCustomsTypeOfShipmentSelect" value-field="id" text-field="name" :options="matterStopCustomsTypeOfShipmentOption" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-visit-date-input">{{ $t('matterStopCustoms.visit_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterStopCustoms.visit_date')" size="sm" v-model="matterStopCustomsVisitDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <b-form>
                        <label for="matter-stop-customs-goods-quantity-input">{{ $t('matterStopCustoms.goods_quantity') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-stop-customs-goods-quantity-input" v-model="matterStopCustomsGoodsQuantity" type="number" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <label for="matter-stop-customs-original-input">{{ $t('matterStopCustoms.original') }}</label>
                    <b-form-checkbox class="custom-control-success" name="matter-stop-customs-original-input" id="matter-stop-customs-original-input" v-model="matterStopCustomsIsOriginal" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4">
                    <label for="matter-stop-customs-is-there-renewal-instruction-input">{{ $t('matterStopCustoms.is_there_renewal_instruction') }}</label>
                    <b-form-checkbox class="custom-control-success" name="matter-stop-customs-is-there-renewal-instruction-input" id="matter-stop-customs-is-there-renewal-instruction-input" v-model="lawMatterStopCustomsIsThereARenewalInstruction" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
                <b-col class="col-lg-4">
                    <label for="matter-stop-customs-is-renewal-instruction-been-send-input">{{ $t('matterStopCustoms.is_renewal_instruction_been_send') }}</label>
                    <b-form-checkbox class="custom-control-success" name="matter-stop-customs-is-renewal-instruction-been-send-input" id="matter-stop-customs-is-renewal-instruction-been-send-input" v-model="lawMatterStopCustomsIsRenewalInstructionBeenSent" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-renewal-reminder-date-input">{{ $t('matterStopCustoms.renewal_reminder_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterStopCustoms.renewal_reminder_date')" size="sm" v-model="lawMatterStopCustomsRenewalReminderDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4">
                    <label for="matter-stop-customs-facilitated-destruction-input">{{ $t('matterStopCustoms.facilitated_destruction') }}</label>
                    <b-form-checkbox class="custom-control-success" name="matter-stop-customs-facilitated-destruction-input" id="matter-stop-customs-facilitated-destruction-input" v-model="lawMatterStopCustomsIsFacilitatedDestruction" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
                <b-col v-if="lawMatterStopCustomsIsFacilitatedDestruction == true" class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-request-date-input">{{ $t('matterStopCustoms.request_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterStopCustoms.request_date')" size="sm" v-model="lawMatterStopCustomsRequestDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col v-if="lawMatterStopCustomsIsFacilitatedDestruction == true" class="col-lg-4">
                    <b-form>
                        <label for="matter-stop-customs-destruction-date-input">{{ $t('matterStopCustoms.destruction_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterStopCustoms.destruction_date')" size="sm" v-model="lawMatterStopCustomsDateOfDestruction" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <label for="matter-stop-customs-goods-input">{{ $t('matterStopCustoms.goods') }}</label>
                        <b-form-group>
                            <b-form-textarea id="matter-stop-customs-goods-input" :placeholder=" $t('matterStopCustoms.goods')" v-model="matterStopCustomsGoods" rows="3" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </b-card>
        <app-collapse class="mb-1 mt-2" type="margin" style="width: 100%" :hidden="hiddenMatterEvidenceDetection">
            <app-collapse-item :title="$t('matter.matter_evidence_detection')">
                <b-row>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-case-number-input">{{ $t('matterEvidenceDetection.case_number') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="matter-evidence-detection-case-number-input" v-model="matterEvidenceDetectionCaseNumber" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-court-input">{{ $t('matterEvidenceDetection.court') }}</label>
                            <b-form-group>
                                <b-form-select id="matter-evidence-detection-court-input" size="sm" v-model="matterEvidenceDetectionCourtSelect" value-field="id" text-field="name" :options="matterEvidenceDetectionCourtOption" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <label for="matter-evidence-detection-is-goods-online-input">{{ $t('matterEvidenceDetection.is_good_online') }}</label>
                        <b-form-checkbox class="custom-control-success" name="matter-evidence-detection-is-goods-online-input" id="matter-evidence-detection-is-goods-online-input" v-model="matterEvidenceDetectionIsGoodsOnline" switch>
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-quantity-input">{{ $t('matterEvidenceDetection.quantity') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="matter-evidence-detection-quantity-input" v-model="matterEvidenceDetectionQuantity" type="number" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-city-input">{{ $t('matterEvidenceDetection.city') }}</label>
                            <b-form-group>
                                <b-form-select id="matter-evidence-detection-city-input" size="sm" v-model="matterEvidenceDetectionCitySelect" value-field="id" text-field="name" :options="matterEvidenceDetectionCityOption" @change="getDistrictList()" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-state-input">{{ $t('matterEvidenceDetection.state') }}</label>
                            <b-form-group>
                                <b-form-select id="matter-evidence-detection-state-input" size="sm" v-model="matterEvidenceDetectionDistrictSelect" value-field="id" text-field="name" :options="matterEvidenceDetectionDistrictOption" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-type-input">{{ $t('matterEvidenceDetection.type') }}</label>
                            <b-form-group>
                                <b-form-select id="matter-evidence-detection-type-input" size="sm" v-model="matterEvidenceDetectionTypeSelect" value-field="id" text-field="name" :options="matterEvidenceDetectionTypeOption" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-filed-on-date-input">{{ $t('matterEvidenceDetection.filed_on_date') }}</label>
                            <b-form-group>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.filed_on_date')" size="sm" v-model="matterEvidenceDetectionFiledOnDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-conducted-on-date-input">{{ $t('matterEvidenceDetection.conducted_on_date') }}</label>
                            <b-form-group>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.conducted_on_date')" size="sm" v-model="matterEvidenceDetectionConductedOnDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-c-d-letter-send-date-input">{{ $t('matterEvidenceDetection.c_d_letter_send_date') }}</label>
                            <b-form-group>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.c_d_letter_send_date')" size="sm" v-model="matterEvidenceDetectionCdLetterSendDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-report-received-date-input">{{ $t('matterEvidenceDetection.report_received_date') }}</label>
                            <b-form-group>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.report_received_date')" size="sm" v-model="matterEvidenceDetectionReportReceivedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-objection-filed-on-date-input">{{ $t('matterEvidenceDetection.objection_filed_on_date') }}</label>
                            <b-form-group>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.objection_filed_on_date')" size="sm" v-model="matterEvidenceDetectionObjectionFiledOnDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-c-d-letter-served-date-input">{{ $t('matterEvidenceDetection.c_d_letter_served_date') }}</label>
                            <b-form-group>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.c_d_letter_served_date')" size="sm" v-model="matterEvidenceDetectionCdLetterServedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-response-received-date-input">{{ $t('matterEvidenceDetection.response_received_date') }}</label>
                            <b-form-group>
                                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterEvidenceDetection.response_received_date')" size="sm" v-model="matterEvidenceDetectionResponseReceivedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                    <b-col class="col-lg-4">
                        <b-form>
                            <label for="matter-evidence-detection-agreed-flat-fee-input">{{ $t('matterEvidenceDetection.agreed_flat_fee') }}</label>
                            <b-form-group>
                                <b-form-input size="sm" id="matter-evidence-detection-agreed-flat-fee-input" v-model="matterEvidenceDetectionAgreedFlatFee" type="number" maxlength="50" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-lg-12">
                        <b-form>
                            <label for="matter-evidence-detection-goods-input">{{ $t('matterEvidenceDetection.goods') }}</label>
                            <b-form-group>
                                <b-form-textarea id="matter-evidence-detection-goods-input" :placeholder=" $t('matterEvidenceDetection.goods')" v-model="matterEvidenceDetectionGoods" rows="3" />
                            </b-form-group>
                        </b-form>
                    </b-col>
                </b-row>
            </app-collapse-item>
        </app-collapse>

        <b-card :title="$t('matter.matter_smuggling')" :hidden="hiddenMatterSmuggling">
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-smuggling-opposite-input">{{ $t('matterSmuggling.opposite') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-smuggling-opposite-input" v-model="matterSmugglingOppositeSide" maxlength="300" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-smuggling-notification-received-input">{{ $t('matterSmuggling.notification_received') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-smuggling-notification-received-input" v-model="matterSmugglingNotificationReceived" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-smuggling-notification-no-input">{{ $t('matterSmuggling.notification_no') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-smuggling-notification-no-input" v-model="matterSmugglingNotificationNo" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="matter-smuggling-received-date-input">{{ $t('matterSmuggling.received_date') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterSmuggling.received_date')" size="sm" v-model="matterSmugglingReceivedDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="matter-smuggling-office-input">{{ $t('matterSmuggling.office') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-smuggling-office-input" v-model="matterSmugglingOffice" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="matter-smuggling-publication-no-input">{{ $t('matterSmuggling.publication_no') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-smuggling-publication-no-input" v-model="matterSmugglingPublicationNo" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-3">
                    <b-form>
                        <label for="matter-smuggling-goods-quantity-input">{{ $t('matterSmuggling.goods_quantity') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-smuggling-goods-quantity-input" v-model="matterSmugglingGoodsQuantity" type="number" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <label for="matter-smuggling-goods-input">{{ $t('matterSmuggling.goods') }}</label>
                        <b-form-group>
                            <b-form-textarea id="matter-smuggling-goods-input" :placeholder=" $t('matterSmuggling.goods')" v-model="matterSmugglingGoods" rows="3" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </b-card>

        <b-card :title="$t('matter.matter_mediation')" :hidden="hiddenMatterMediation">
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-filed-on-input">{{ $t('matterMediation.filed_on') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterMediation.filed_on')" size="sm" v-model="matterMediationFiledOn" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-MediationOffice-input">{{ $t('matterMediation.mediationOffice') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-mediation-MediationOffice-input" v-model="matterMediationMediationOffice" maxlength="100" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-case-number-input">{{ $t('matterMediation.case_number') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-mediation-case-number-input" v-model="matterMediationCaseNumber" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-MediatorsNameAndSurname-input">{{ $t('matterMediation.mediatorsNameAndSurname') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-mediation-MediatorsNameAndSurname-input" v-model="matterMediationMediatorsNameAndSurname" maxlength="250" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-MediatorsContactInformation-input">{{ $t('matterMediation.mediatorsContactInformation') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-mediation-MediatorsContactInformation-input" v-model="matterMediationMediatorsContactInformation" maxlength="250" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-InvitationLetterReceived-input">{{ $t('matterMediation.invitationLetterReceived') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterMediation.invitationLetterReceived')" size="sm" v-model="matterMediationInvitationLetterReceivedOn" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-DateOfFirstMeetingMinutes-input">{{ $t('matterMediation.dateOfFirstMeetingMinutes') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterMediation.dateOfFirstMeetingMinutes')" size="sm" v-model="matterMediationDateOfFirstMeetingMinutes" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-DateOfLastMeetingMinutes-input">{{ $t('matterMediation.dateOfLastMeetingMinutes') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterMediation.dateOfLastMeetingMinutes')" size="sm" v-model="matterMediationDateOfLastMeetingMinutes" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-4">
                    <label for="matter-mediation-isAgreement-input">{{ $t('matterMediation.isAgreement') }}</label>
                    <b-form-checkbox class="custom-control-success" name="matter-mediation-isAgreement-input" id="matter-mediation-isAgreement-input" v-model="matterMediationIsAgreement" switch>
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                        </span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-AgreementAmount-input">{{ $t('matterMediation.agreementAmount') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-mediation-AgreementAmount-input" v-model="matterMediationAgreementAmount" type="number" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-CollectionDate-input">{{ $t('matterMediation.collectionDate') }}</label>
                        <b-form-group>
                            <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('matterMediation.collectionDate')" size="sm" v-model="matterMediationCollectionDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                        </b-form-group>
                    </b-form>
                </b-col>

                <b-col class="col-lg-4">
                    <b-form>
                        <label for="matter-mediation-PenaltyClauseAmount-input">{{ $t('matterMediation.penaltyClauseAmount') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="matter-mediation-PenaltyClauseAmount-input" v-model="matterMediationPenaltyClauseAmount" type="number" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </b-card>

        <!-- STOCK-1105 -->
        <b-card :title="$t('matter.matter_note')" :hidden="hiddenControl">
            <b-col class="col-lg-12">
                <b-form>
                    <b-form-group>
                        <b-form-textarea id="law-matter-note-visible-area" disabled :placeholder=" $t('matter.matter_note')" v-model="lawMatterAllNotesAsString" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-card>
        <!-- STOCK-1105 -->
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab v-if="matterTypeSelect == 1 && id > 0" :title="$t('matter.matter_plaintiff')" style="width:100%;">
                            <b-card-text>
                                <matter-plaintiff-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-plaintiff-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('matter.matter_document')" style="width:100%;">
                            <b-card-text>
                                <matter-document-list ref="documentList" :getMatterList="getMatterList"></matter-document-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('matter.matter_note')" style="width:100%;">
                            <b-card-text>
                                <matter-note-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-note-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="matterTypeSelect == 1 && id > 0" :title="$t('matter.matter_first_instance_court')" style="width:100%;">
                            <b-card-text>
                                <matter-first-instance-court-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-first-instance-court-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="matterTypeSelect == 1 && id > 0" :title="$t('matter.matter_regional_court_of_appeal')" style="width:100%;">
                            <b-card-text>
                                <matter-regional-court-of-appeal-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-regional-court-of-appeal-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="matterTypeSelect == 1 && id > 0" :title="$t('matter.matter_appeal_info')" style="width:100%;">
                            <b-card-text>
                                <matter-appeal-info-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-appeal-info-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="matterTypeSelect == 1 && id > 0" :title="$t('matter.matter_supreme_court')" style="width:100%;">
                            <b-card-text>
                                <matter-supreme-court-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-supreme-court-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="matterTypeSelect == 1 && id > 0" :title="$t('matter.matter_decision_adjustments')" style="width:100%;">
                            <b-card-text>
                                <matter-decision-adjustments-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-decision-adjustments-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="matterTypeSelect == 1 && id > 0" :title="$t('matter.matter_finalization')" style="width:100%;">
                            <b-card-text>
                                <matter-finalization-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-finalization-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="matterTypeSelect == 1 && id > 0" :title="$t('matter.matter_caution')" style="width:100%;">
                            <b-card-text>
                                <matter-caution-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-caution-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('matter.evidence')" style="width:100%;">
                            <b-card-text>
                                <law-matter-evidence-list :getLawMatterList="getMatterList" :lawMatterId="this.id"></law-matter-evidence-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('matter.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :getMatterList="getMatterList" :lawMatterId="this.id"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="matterTypeSelect == 1 && id > 0" :title="$t('matter.matter_budget')" style="width:100%;">
                            <b-card-text>
                                <matter-budget-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-budget-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('matter.invoice')" style="width:100%;">
                            <b-card-text>
                                <invoice-detail-list :getMatterList="getMatterList" :lawMatterId="this.id"></invoice-detail-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="matterTypeSelect == 1 && id > 0 && matterSubTypeSelect == 1 && lawMatterCaseTypeSelect== 2" :title="$t('matter.matter_defendant')" style="width:100%;">
                            <b-card-text>
                                <matter-defendant-list :getMatterList="getMatterList" :lawMatterId="this.id"></matter-defendant-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getMatterList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            matterClientId: 0,
            matterClientSelect: 0,
            matterClientOption: [],
            invoiceAgentSelect: 0,
            invoiceAgentOption: [],
            agentSelect: 0,
            agentOption: [],
            matterTypeSelect: 0,
            matterTypeOption: [],
            matterSubTypeSelect: 0,
            matterSubTypeOption: [],
            matterStatusSelect: 0,
            matterStatusOption: [],
            matterFileTypeSelect: 0,
            matterFileTypeOption: [],
            recordNo: '',
            agentReferenceNo: '',
            operationRecordNo: '',
            recordDate: null,
            name: '',
            shortName: '',
            matterType: '',
            firstLawyerSelect: 0,
            firstLawyerOption: [],
            secondLawyerSelect: 0,
            secondLawyerOption: [],
            hiddenControl: true,
            districtList: [],
            lawMatterAllNotesAsString: "",
            lawMatterCaseTypeOption: [],
            lawMatterCaseTypeSelect: 0,
            lawClientPowerOfAttorneyDeadlineDate: null,
            lawMatterPlaintiffAsString: '',
            //MatterInfo
            hiddenMatterInfo: false,
            matterInfoSubjectSelect: 0,
            matterInfoSubjectOption: [],
            matterInfoCourtSelect: 0,
            matterInfoCourtOption: [],
            matterInfoCaseNumber: '',
            matterInfoFiledOnDate: null,

            //MatterConsulting
            hiddenMatterConsulting: false,
            matterConsultingCdLetterSentDate: null,
            matterConsultingCdLetterServedDate: null,
            matterConsultingReceivedDate: null,
            matterConsultingOppositeSide: '',
            matterConsultingMatterOutcomeSelect: 0,
            matterConsultingMatterOutcomeOption: [],

            //MatterCustomsRegistration
            hiddenMatterCustomsRegistration: false,
            matterCustomsRegistrationConclusionSelect: 0,
            matterCustomsRegistrationConclusionOption: [],
            matterCustomsRegistrationNumber: '',
            matterCustomsRegistrationFirstApplicationDate: null,
            matterCustomsRegistrationApplicationNo: '',
            matterCustomsRegistrationApplicationDate: null,
            matterCustomsRegistrationResponseDate: null,
            matterCustomsRegistrationExpirationDate: null,
            matterCustomsRegistrationTrademarkName: '',
            lawMatterCustomsRegistrationIsRenewalInstructionBeenSent: false,
            lawMatterCustomsRegistrationRenewalReminderDate: null,
            lawMatterCustomsRegistrationIsThereARenewalInstruction: false,

            //MatterStopCustoms
            hiddenMatterStopCustoms: false,
            matterStopCustomsOffice: '',
            matterStopCustomsDecisionNo: '',
            matterStopCustomsDecisionDate: null,
            matterStopCustomsOppositeSide: '',
            matterStopCustomsSender: '',
            matterStopCustomsBearer: '',
            matterStopCustomsTypeOfShipmentSelect: 0,
            matterStopCustomsTypeOfShipmentOption: [],
            matterStopCustomsVisitDate: null,
            matterStopCustomsGoodsQuantity: '',
            matterStopCustomsIsOriginal: false,
            matterStopCustomsGoods: '',
            lawMatterStopCustomsIsRenewalInstructionBeenSent: false,
            lawMatterStopCustomsIsThereARenewalInstruction: false,
            lawMatterStopCustomsRenewalReminderDate: null,
            lawMatterStopCustomsIsFacilitatedDestruction: false,
            lawMatterStopCustomsRequestDate: null,
            lawMatterStopCustomsDateOfDestruction: null,

            //
            //MatterEvidenceDetection
            hiddenMatterEvidenceDetection: false,
            matterEvidenceDetectionCaseNumber: '',
            matterEvidenceDetectionCourtSelect: 0,
            matterEvidenceDetectionCourtOption: [],
            matterEvidenceDetectionIsGoodsOnline: false,
            matterEvidenceDetectionQuantity: '',
            matterEvidenceDetectionCitySelect: 0,
            matterEvidenceDetectionCityOption: [],
            matterEvidenceDetectionDistrictSelect: 0,
            matterEvidenceDetectionDistrictOption: [],
            matterEvidenceDetectionTypeSelect: 0,
            matterEvidenceDetectionTypeOption: [],
            matterEvidenceDetectionFiledOnDate: null,
            matterEvidenceDetectionConductedOnDate: null,
            matterEvidenceDetectionCdLetterSendDate: null,
            matterEvidenceDetectionReportReceivedDate: null,
            matterEvidenceDetectionObjectionFiledOnDate: null,
            matterEvidenceDetectionCdLetterServedDate: null,
            matterEvidenceDetectionResponseReceivedDate: null,
            matterEvidenceDetectionAgreedFlatFee: 0,
            matterEvidenceDetectionGoods: '',

            //MatterSmuggling
            hiddenMatterSmuggling: false,
            matterSmugglingOppositeSide: '',
            matterSmugglingNotificationReceived: '',
            matterSmugglingNotificationNo: '',
            matterSmugglingReceivedDate: null,
            matterSmugglingOffice: '',
            matterSmugglingPublicationNo: '',
            matterSmugglingGoodsQuantity: '',
            matterSmugglingGoods: '',

            //MatterMediation
            hiddenMatterMediation: false,
            matterMediationFiledOn: null,
            matterMediationMediationOffice: '',
            matterMediationCaseNumber: '',
            matterMediationMediatorsNameAndSurname: '',
            matterMediationMediatorsContactInformation: '',
            matterMediationInvitationLetterReceivedOn: null,
            matterMediationDateOfFirstMeetingMinutes: null,
            matterMediationDateOfLastMeetingMinutes: null,
            matterMediationIsAgreement: false,
            matterMediationAgreementAmount: 0,
            matterMediationCollectionDate: null,
            matterMediationPenaltyClauseAmount: 0,

        }
    },
    methods: {
        getDistrictList() {
            if (this.matterEvidenceDetectionCitySelect > 0) {
                this.matterEvidenceDetectionDistrictOption = this.districtList.filter(p => p.cityId == this.matterEvidenceDetectionCitySelect)
                this.matterEvidenceDetectionDistrictSelect = 0;
                this.matterEvidenceDetectionDistrictOption.splice(0, 0, this.$nullSelected())
            }
        },
        changeTabStatus() {
            if (this.id != 0) {
                this.hiddenMatterInfo = true
                this.hiddenMatterConsulting = true
                this.hiddenMatterCustomsRegistration = true
                this.hiddenMatterStopCustoms = true
                this.hiddenMatterEvidenceDetection = true
                this.hiddenMatterSmuggling = true
                this.hiddenMatterMediation = true

                if (this.matterTypeSelect == 1) {
                    this.hiddenMatterInfo = false;
                    this.hiddenMatterEvidenceDetection = false;
                } else if (this.matterTypeSelect == 2)
                    this.hiddenMatterConsulting = false;
                else if (this.matterTypeSelect == 3)
                    this.hiddenMatterCustomsRegistration = false;
                else if (this.matterTypeSelect == 4)
                    this.hiddenMatterStopCustoms = false;
                else if (this.matterTypeSelect == 5)
                    this.hiddenMatterEvidenceDetection = false;
                else if (this.matterTypeSelect == 7)
                    this.hiddenMatterSmuggling = false;
                else if (this.matterTypeSelect == 10)
                    this.hiddenMatterMediation = false;
            }
        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        setMatterClientId(event) {
            if (event != null) {
                this.matterClientId = event.id
            } else {
                this.matterClientId = null;
            }
        },
        onAgentSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.agentSearch(loading, search, this);
            }
        },
        onInvoiceAgentSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.invoiceAgentSearch(loading, search, this);
            }
        },
        agentSearch(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'LawClient',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.agentOption = response.data.clientList
                    loading(false)
                })
            }
        },

        invoiceAgentSearch(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'LawClient',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.invoiceAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },

        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    name: search
                }
                const controller = {
                    name: 'LawClient',
                    actionName: 'GetOwnerClientByName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.matterClientOption = response.data.clientList
                    loading(false)
                })
            }
        },
        showInfo(id) {
            this.$refs['matterFormModal'].show()
            this.id = id
            if (this.id == 0) {
                this.hiddenControl = true
            } else {
                this.hiddenControl = false
            }
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatter'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.lawMatterAllNotesAsString = response.data.lawMatterAllNotesAsString
                    this.matterTypeSelect = response.data.lawMatter.lawMatterTypeId

                    this.matterTypeOption = response.data.lawMatterTypeList
                    this.matterSubTypeSelect = response.data.lawMatter.lawMatterSubTypeId
                    this.matterSubTypeOption = response.data.lawMatterSubTypeList
                    this.matterStatusSelect = response.data.lawMatter.lawMatterStatusId
                    if (this.matterStatusSelect == 0) {
                        this.matterStatusSelect = 1
                    }
                    this.matterStatusOption = response.data.lawMatterStatusList
                    this.matterFileTypeSelect = response.data.lawMatter.lawMatterFileTypeId
                    if (this.matterFileTypeSelect == 0) {
                        this.matterFileTypeSelect = 2
                    }
                    this.matterFileTypeOption = response.data.lawMatterFileTypeList
                    this.recordNo = response.data.lawMatter.recordNo
                    this.agentReferenceNo = response.data.lawMatter.agentReferenceNo
                    this.operationRecordNo = response.data.lawMatter.operationRecordNo
                    this.name = response.data.lawMatter.name
                    this.recordDate = this.$formatFn(response.data.lawMatter.recordDate)
                    this.shortName = response.data.lawMatter.shortName
                    this.firstLawyerSelect = response.data.lawMatter.firstLawyerId
                    this.firstLawyerOption = response.data.lawMatterFirstLawyerList
                    this.secondLawyerSelect = response.data.lawMatter.secondLawyerId
                    this.secondLawyerOption = response.data.lawMatterSecondLawyerList
                    this.lawMatterCaseTypeOption = response.data.lawMatterCaseTypeList
                    this.lawMatterCaseTypeOption.splice(0, 0, this.$nullSelected())
                    this.lawMatterCaseTypeSelect = response.data.lawMatter.lawMatterCaseTypeId
                    this.matterTypeOption.splice(0, 0, this.$nullSelected())
                    this.matterSubTypeOption.splice(0, 0, this.$nullSelected())
                    this.matterStatusOption.splice(0, 0, this.$nullSelected())
                    this.matterFileTypeOption.splice(0, 0, this.$nullSelected())
                    this.firstLawyerOption.splice(0, 0, this.$nullSelected())
                    this.secondLawyerOption.splice(0, 0, this.$nullSelected())
                    this.lawClientPowerOfAttorneyDeadlineDate = response.data.lawClientPowerOfAttorneyDeadlineDate
                    //STOCK-1297
                    this.lawMatterPlaintiffAsString = response.data.lawMatterPlaintiffAsString
                    //
                    if (this.matterTypeSelect > 0)
                        this.matterType = this.matterTypeOption.filter(p => p.id == this.matterTypeSelect)[0].name
                    else
                        this.matterType = ''

                    if (response.data.lawMatter.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.lawMatter.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.agentOption = clientAccountingResponse.data.clientList
                            this.agentSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.agentOption = []
                        this.agentSelect = 0;
                    }

                    if (response.data.lawMatter.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.lawMatter.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.invoiceAgentOption = clientAccountingResponse.data.clientList
                            this.invoiceAgentSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.invoiceAgentOption = []
                        this.invoiceAgentSelect = 0;
                    }

                    if (response.data.lawMatter.clientId > 0) {
                        const matterClientData = {
                            clientId: response.data.lawMatter.clientId
                        }
                        const matterClientController = {
                            name: 'Client',
                            actionName: 'GetOwnerClientByName'
                        }
                        const payload = {
                            data: matterClientData,
                            controller: matterClientController
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((matterClientResponse) => {
                            this.matterClientOption = matterClientResponse.data.clientList
                            this.matterClientSelect = matterClientResponse.data.clientList[0]
                            this.clientId = matterClientResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.matterClientOption = [];
                        this.matterClientSelect = 0;
                    }

                    this.matterInfoCourtOption = response.data.lawCourtList
                    this.matterInfoSubjectOption = response.data.lawSubjectList
                    if (this.matterInfoSubjectOption != null) {
                        this.matterInfoSubjectOption.splice(0, 0, this.$nullSelected())
                    }
                    if (this.matterInfoCourtOption != null) {
                        this.matterInfoCourtOption.splice(0, 0, this.$nullSelected())
                    }
                    if (this.matterTypeSelect == 1) {
                        this.hiddenMatterInfo = false;

                        this.matterInfoSubjectSelect = response.data.lawMatterInfo.lawSubjectId
                        this.matterInfoCourtSelect = response.data.lawMatterInfo.lawCourtId
                        this.matterInfoCaseNumber = response.data.lawMatterInfo.caseNumber
                        this.matterInfoFiledOnDate = response.data.lawMatterInfo.date
                    } else
                        this.hiddenMatterInfo = true;

                    this.matterConsultingMatterOutcomeOption = response.data.lawMatterOutcomeList
                    if (this.matterConsultingMatterOutcomeOption != null) {
                        this.matterConsultingMatterOutcomeOption.splice(0, 0, this.$nullSelected())
                    }
                    if (this.matterTypeSelect == 2) {
                        this.hiddenMatterConsulting = false;

                        this.matterConsultingCdLetterSentDate = response.data.lawMatterConsulting.cdLetterSentDate
                        this.matterConsultingCdLetterServedDate = response.data.lawMatterConsulting.cdLetterServedDate
                        this.matterConsultingReceivedDate = response.data.lawMatterConsulting.receivedDate
                        this.matterConsultingOppositeSide = response.data.lawMatterConsulting.oppositeSide
                        this.matterConsultingMatterOutcomeSelect = response.data.lawMatterConsulting.lawMatterOutcomeId

                    } else
                        this.hiddenMatterConsulting = true;

                    this.matterCustomsRegistrationConclusionOption = response.data.lawMatterConclusionList
                    if (this.matterCustomsRegistrationConclusionOption != null) {
                        this.matterCustomsRegistrationConclusionOption.splice(0, 0, this.$nullSelected())
                    }
                    if (this.matterTypeSelect == 3) {
                        this.hiddenMatterCustomsRegistration = false;

                        this.matterCustomsRegistrationConclusionSelect = response.data.lawMatterCustomsRegistration.lawMatterConclusionId
                        this.matterCustomsRegistrationNumber = response.data.lawMatterCustomsRegistration.registrationNumber
                        this.matterCustomsRegistrationFirstApplicationDate = response.data.lawMatterCustomsRegistration.firstApplicationDate
                        this.matterCustomsRegistrationApplicationNo = response.data.lawMatterCustomsRegistration.applicationNo
                        this.matterCustomsRegistrationApplicationDate = response.data.lawMatterCustomsRegistration.applicationDate
                        this.matterCustomsRegistrationResponseDate = response.data.lawMatterCustomsRegistration.responseDate
                        this.matterCustomsRegistrationExpirationDate = response.data.lawMatterCustomsRegistration.expirationDate
                        this.matterCustomsRegistrationTrademarkName = response.data.lawMatterCustomsRegistration.trademarkName,
                            //STOCK-1135
                            // Changed names same as API
                            this.lawMatterCustomsRegistrationIsRenewalInstructionBeenSent = response.data.lawMatterCustomsRegistration.isRenewalInstructionBeenSent,
                            this.lawMatterCustomsRegistrationRenewalReminderDate = response.data.lawMatterCustomsRegistration.renewalReminderDate,
                            this.lawMatterCustomsRegistrationIsThereARenewalInstruction = response.data.lawMatterCustomsRegistration.isThereARenewalInstruction
                        //
                    } else
                        this.hiddenMatterCustomsRegistration = true;

                    this.matterStopCustomsTypeOfShipmentOption = response.data.lawTypeOfShipmentList
                    if (this.matterStopCustomsTypeOfShipmentOption != null) {
                        this.matterStopCustomsTypeOfShipmentOption.splice(0, 0, this.$nullSelected())
                    }
                    if (this.matterTypeSelect == 4) {
                        this.hiddenMatterStopCustoms = false;

                        this.matterStopCustomsOffice = response.data.lawMatterStopCustoms.office
                        this.matterStopCustomsDecisionNo = response.data.lawMatterStopCustoms.decisionNo
                        this.matterStopCustomsDecisionDate = response.data.lawMatterStopCustoms.decisionDate
                        this.matterStopCustomsOppositeSide = response.data.lawMatterStopCustoms.oppositeSide
                        this.matterStopCustomsSender = response.data.lawMatterStopCustoms.sender
                        this.matterStopCustomsBearer = response.data.lawMatterStopCustoms.bearer
                        this.matterStopCustomsTypeOfShipmentSelect = response.data.lawMatterStopCustoms.typeOfShipmentId

                        this.matterStopCustomsVisitDate = response.data.lawMatterStopCustoms.visitDate
                        this.matterStopCustomsGoodsQuantity = response.data.lawMatterStopCustoms.goodsQuantity
                        this.matterStopCustomsIsOriginal = response.data.lawMatterStopCustoms.isOriginal
                        this.matterStopCustomsGoods = response.data.lawMatterStopCustoms.goods,
                            this.lawMatterStopCustomsIsFacilitatedDestruction = response.data.lawMatterStopCustoms.isFacilitatedDestruction
                        this.lawMatterStopCustomsRequestDate = response.data.lawMatterStopCustoms.requestDate
                        this.lawMatterStopCustomsDateOfDestruction = response.data.lawMatterStopCustoms.dateOfDestruction

                        this.lawMatterStopCustomsIsRenewalInstructionBeenSent = response.data.lawMatterStopCustoms.isRenewalInstructionBeenSent
                        this.lawMatterStopCustomsIsThereARenewalInstruction = response.data.lawMatterStopCustoms.isThereARenewalInstruction
                        this.lawMatterStopCustomsRenewalReminderDate = response.data.lawMatterStopCustoms.renewalReminderDate
                        //
                    } else
                        this.hiddenMatterStopCustoms = true;

                    this.matterEvidenceDetectionCourtOption = response.data.lawCourtList
                    this.matterEvidenceDetectionCityOption = response.data.cityList
                    this.districtList = response.data.districtList
                    this.matterEvidenceDetectionTypeOption = response.data.lawEvidenceDetectionTypeList

                    if (this.matterEvidenceDetectionTypeOption != null) {
                        this.matterEvidenceDetectionTypeOption.splice(0, 0, this.$nullSelected())
                    }
                    if (this.matterEvidenceDetectionCityOption != null) {
                        this.matterEvidenceDetectionCityOption.splice(0, 0, this.$nullSelected())
                    }
                    if (this.matterTypeSelect == 1 || this.matterTypeSelect == 5) {
                            this.hiddenMatterEvidenceDetection = false;
                            this.matterEvidenceDetectionCaseNumber = response.data.lawMatterEvidenceDetection.caseNumber
                            this.matterEvidenceDetectionCourtSelect = response.data.lawMatterEvidenceDetection.courtId
                            this.matterEvidenceDetectionIsGoodsOnline = response.data.lawMatterEvidenceDetection.isGoodsOnline
                            this.matterEvidenceDetectionQuantity = response.data.lawMatterEvidenceDetection.quantity
                            this.matterEvidenceDetectionCitySelect = response.data.lawMatterEvidenceDetection.cityId
                            this.matterEvidenceDetectionTypeSelect = response.data.lawMatterEvidenceDetection.typeId
                            this.matterEvidenceDetectionFiledOnDate = response.data.lawMatterEvidenceDetection.filedOnDate
                            this.matterEvidenceDetectionConductedOnDate = response.data.lawMatterEvidenceDetection.conductedOnDate
                            this.matterEvidenceDetectionCdLetterSendDate = response.data.lawMatterEvidenceDetection.cdLetterSendDate
                            this.matterEvidenceDetectionReportReceivedDate = response.data.lawMatterEvidenceDetection.reportReceivedDate
                            this.matterEvidenceDetectionObjectionFiledOnDate = response.data.lawMatterEvidenceDetection.objectionFiledOnDate
                            this.matterEvidenceDetectionCdLetterServedDate = response.data.lawMatterEvidenceDetection.cdLetterServedDate
                            this.matterEvidenceDetectionResponseReceivedDate = response.data.lawMatterEvidenceDetection.responseReceivedDate
                            this.matterEvidenceDetectionAgreedFlatFee = response.data.lawMatterEvidenceDetection.agreedFlatFee
                            this.matterEvidenceDetectionGoods = response.data.lawMatterEvidenceDetection.goods
                        if (response.data.lawMatterEvidenceDetection.cityId != 0) {
                            this.matterEvidenceDetectionDistrictOption = this.districtList.filter(p => p.cityId == response.data.lawMatterEvidenceDetection.cityId)
                            this.matterEvidenceDetectionDistrictSelect = response.data.lawMatterEvidenceDetection.districtId;
                            this.matterEvidenceDetectionDistrictOption.splice(0, 0, this.$nullSelected())
                        }
                    } else
                        this.hiddenMatterEvidenceDetection = true;

                    if (this.matterTypeSelect == 7) {
                        this.hiddenMatterSmuggling = false;

                        this.matterSmugglingOppositeSide = response.data.lawMatterSmuggling.oppositeSide
                        this.matterSmugglingNotificationReceived = response.data.lawMatterSmuggling.notificationReceived
                        this.matterSmugglingNotificationNo = response.data.lawMatterSmuggling.notificationNo
                        this.matterSmugglingReceivedDate = response.data.lawMatterSmuggling.receivedDate
                        this.matterSmugglingOffice = response.data.lawMatterSmuggling.office
                        this.matterSmugglingPublicationNo = response.data.lawMatterSmuggling.publicationNo
                        this.matterSmugglingGoodsQuantity = response.data.lawMatterSmuggling.goodsQuantity
                        this.matterSmugglingGoods = response.data.lawMatterSmuggling.goods
                    } else
                        this.hiddenMatterSmuggling = true;

                    if (this.matterTypeSelect == 10) {
                        this.hiddenMatterMediation = false;

                        this.matterMediationFiledOn = response.data.lawMatterMediation.filedOn
                        this.matterMediationMediationOffice = response.data.lawMatterMediation.mediationOffice
                        this.matterMediationCaseNumber = response.data.lawMatterMediation.caseNumber
                        this.matterMediationMediatorsNameAndSurname = response.data.lawMatterMediation.mediatorsNameAndSurname
                        this.matterMediationMediatorsContactInformation = response.data.lawMatterMediation.mediatorsContactInformation
                        this.matterMediationInvitationLetterReceivedOn = response.data.lawMatterMediation.invitationLetterReceivedOn
                        this.matterMediationDateOfFirstMeetingMinutes = response.data.lawMatterMediation.dateOfFirstMeetingMinutes
                        this.matterMediationDateOfLastMeetingMinutes = response.data.lawMatterMediation.dateOfLastMeetingMinutes
                        this.matterMediationIsAgreement = response.data.lawMatterMediation.isAgreement
                        this.matterMediationAgreementAmount = response.data.lawMatterMediation.agreementAmount
                        this.matterMediationCollectionDate = response.data.lawMatterMediation.collectionDate
                        this.matterMediationPenaltyClauseAmount = response.data.lawMatterMediation.penaltyClauseAmount
                    } else
                        this.hiddenMatterMediation = true;
                    this.$refs.documentList.getMatterDocumentList(22, this.id)
                } else {
                    this.$bvModal.hide('matterFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const lawMatterData = {
                        id: this.id,
                        clientId: this.matterClientSelect.id,
                        clientAccountingId: this.agentSelect.clientAccountingId,
                        invoiceClientAccountingId: this.invoiceAgentSelect.clientAccountingId,
                        lawMatterStatusId: this.matterStatusSelect,
                        lawMatterSubTypeId: this.matterSubTypeSelect,
                        lawMatterTypeId: this.matterTypeSelect,
                        lawMatterFileTypeId: this.matterFileTypeSelect,
                        recordNo: this.recordNo,
                        agentReferenceNo: this.agentReferenceNo,
                        operationRecordNo: this.operationRecordNo,
                        name: this.name,
                        shortName: this.shortName,
                        firstLawyerId: this.firstLawyerSelect,
                        secondLawyerId: this.secondLawyerSelect,
                        lawMatterCaseTypeId: this.lawMatterCaseTypeSelect
                    }
                    let lawMatterInfoData = null
                    if (this.matterTypeSelect == 1) {
                        lawMatterInfoData = {
                            lawSubjectId: this.matterInfoSubjectSelect,
                            lawCourtId: this.matterInfoCourtSelect,
                            caseNumber: this.matterInfoCaseNumber,
                            date: this.matterInfoFiledOnDate,
                        }
                    }
                    let lawMatterConsultingData = null
                    if (this.matterTypeSelect == 2) {
                        lawMatterConsultingData = {
                            cdLetterSentDate: this.matterConsultingCdLetterSentDate,
                            cdLetterServedDate: this.matterConsultingCdLetterServedDate,
                            oppositeSide: this.matterConsultingOppositeSide,
                            lawMatterOutcomeId: this.matterConsultingMatterOutcomeSelect,
                            receivedDate: this.matterConsultingReceivedDate
                        }
                    }
                    let lawMatterCustomsRegistrationData = null
                    if (this.matterTypeSelect == 3) {
                        lawMatterCustomsRegistrationData = {
                            lawMatterConclusionId: this.matterCustomsRegistrationConclusionSelect,
                            registrationNumber: this.matterCustomsRegistrationNumber,
                            firstApplicationDate: this.matterCustomsRegistrationFirstApplicationDate,
                            applicationNo: this.matterCustomsRegistrationApplicationNo,
                            applicationDate: this.matterCustomsRegistrationApplicationDate,
                            responseDate: this.matterCustomsRegistrationResponseDate,
                            expirationDate: this.matterCustomsRegistrationExpirationDate,
                            trademarkName: this.matterCustomsRegistrationTrademarkName,
                            isRenewalInstructionBeenSent: this.lawMatterCustomsRegistrationIsRenewalInstructionBeenSent,
                            renewalReminderDate: this.lawMatterCustomsRegistrationRenewalReminderDate,
                            isThereARenewalInstruction: this.lawMatterCustomsRegistrationIsThereARenewalInstruction
                        }
                    }
                    let lawMatterStopCustomsData = null
                    if (this.matterTypeSelect == 4) {
                        lawMatterStopCustomsData = {
                            office: this.matterStopCustomsOffice,
                            decisionNo: this.matterStopCustomsDecisionNo,
                            decisionDate: this.matterStopCustomsDecisionDate,
                            oppositeSide: this.matterStopCustomsOppositeSide,
                            sender: this.matterStopCustomsSender,
                            bearer: this.matterStopCustomsBearer,
                            typeOfShipmentId: this.matterStopCustomsTypeOfShipmentSelect,
                            visitDate: this.matterStopCustomsVisitDate,
                            goodsQuantity: this.matterStopCustomsGoodsQuantity,
                            isOriginal: this.matterStopCustomsIsOriginal,
                            goods: this.matterStopCustomsGoods,
                            isFacilitatedDestruction: this.lawMatterStopCustomsIsFacilitatedDestruction,
                            requestDate: this.lawMatterStopCustomsRequestDate,
                            dateOfDestruction: this.lawMatterStopCustomsDateOfDestruction
                        }
                    }
                    let lawMatterEvidenceDetectionData = null
                    if (this.matterTypeSelect == 1 || this.matterTypeSelect == 5) {
                        lawMatterEvidenceDetectionData = {
                            caseNumber: this.matterEvidenceDetectionCaseNumber,
                            LawCourtId: this.matterEvidenceDetectionLawCourtSelect,
                            isGoodsOnline: this.matterEvidenceDetectionIsGoodsOnline,
                            quantity: this.matterEvidenceDetectionQuantity,
                            cityId: this.matterEvidenceDetectionCitySelect,
                            districtId: this.matterEvidenceDetectionDistrictSelect,
                            lawEvidenceDetectionTypeId: this.matterEvidenceDetectionTypeSelect,
                            filedOnDate: this.matterEvidenceDetectionFiledOnDate,
                            conductedOnDate: this.matterEvidenceDetectionConductedOnDate,
                            cdLetterSendDate: this.matterEvidenceDetectionCdLetterSendDate,
                            reportReceivedDate: this.ReportReceivedDate,
                            objectionFiledOnDate: this.matterEvidenceDetectionObjectionFiledOnDate,
                            cdLetterServedDate: this.matterEvidenceDetectionCdLetterServedDate,
                            responseReceivedDate: this.matterEvidenceDetectionResponseReceivedDate,
                            agreedFlatFee: this.matterEvidenceDetectionAgreedFlatFee,
                            goods: this.matterEvidenceDetectionGoods,
                        }
                    }
                    let lawMatterSmugglingData = null
                    if (this.matterTypeSelect == 7) {
                        lawMatterSmugglingData = {
                            oppositeSide: this.matterSmugglingOppositeSide,
                            notificationReceived: this.matterSmugglingNotificationReceived,
                            notificationNo: this.matterSmugglingNotificationNo,
                            receivedDate: this.matterSmugglingReceivedDate,
                            office: this.matterSmugglingOffice,
                            publicationNo: this.matterSmugglingPublicationNo,
                            goodsQuantity: this.matterSmugglingGoodsQuantity,
                            goods: this.matterSmugglingGoods
                        }
                    }
                    let lawMatterMediationData = null
                    if (this.matterTypeSelect == 10) {
                        lawMatterMediationData = {
                            filedOn: this.matterMediationFiledOn,
                            mediationOffice: this.matterMediationMediationOffice,
                            caseNumber: this.matterMediationCaseNumber,
                            mediatorsNameAndSurname: this.matterMediationMediatorsNameAndSurname,
                            mediatorsContactInformation: this.matterMediationMediatorsContactInformation,
                            invitationLetterReceivedOn: this.matterMediationInvitationLetterReceivedOn,
                            dateOfFirstMeetingMinutes: this.matterMediationDateOfFirstMeetingMinutes,
                            dateOfLastMeetingMinutes: this.matterMediationDateOfLastMeetingMinutes,
                            isAgreement: this.matterMediationIsAgreement,
                            agreementAmount: this.matterMediationAgreementAmount,
                            collectionDate: this.matterMediationCollectionDate,
                            penaltyClauseAmount: this.matterMediationPenaltyClauseAmount
                        }
                    }

                    const data = {
                        lawMatter: lawMatterData,
                        lawMatterConsulting: lawMatterConsultingData,
                        lawMatterInfo: lawMatterInfoData,
                        lawMatterCustomsRegistration: lawMatterCustomsRegistrationData,
                        lawMatterStopCustoms: lawMatterStopCustomsData,
                        lawMatterEvidenceDetection: lawMatterEvidenceDetectionData,
                        lawMatterSmuggling: lawMatterSmugglingData,
                        lawMatterMediation: lawMatterMediationData
                    }
                    const controller = {
                        name: 'LawMatter'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {

                            if (this.id == 0) {
                                this.hiddenControl = false
                                this.id = response.data.lawMatterId
                                this.recordNo = response.data.recordNo
                            }

                            this.getMatterList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
