<template>
<div>
    <!-- modal -->
    <b-modal id="lawMatterEvidenceFormModal" ref="lawMatterEvidenceFormModal" centered size="lg" no-close-on-backdrop :title=" $t('lawMatterEvidence.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <b-row>
            <b-col class="col-lg-6">
                <label for="law-matter-evidence-is-original-product-input">{{ $t('lawMatterEvidence.is_original_product') }}</label>
                <b-form-checkbox class="custom-control-success" name="law-matter-evidence-is-original-product-input" id="law-matter-evidence-is-original-product-input" v-model="isOriginalProduct" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
            <b-col class="col-lg-6">
                <label for="law-matter-evidence-is-imitation-product-input">{{ $t('lawMatterEvidence.is_imitation_product') }}</label>
                <b-form-checkbox class="custom-control-success" name="law-matter-evidence-is-imitation-product-input" id="law-matter-evidence-is-imitation-product-input" v-model="isImitationProduct" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col class="col-lg-4">
                <b-form>
                    <label for="law-matter-evidence-purchase-date-input">{{ $t('lawMatterEvidence.purchase_date') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('lawMatterEvidence.purchase_date')" size="sm" v-model="purchaseDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <b-form>
                    <label for="law-matter-evidence-finalization-date-input">{{ $t('lawMatterEvidence.arrival_date_from_client') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('lawMatterEvidence.arrival_date_from_client')" size="sm" v-model="arrivalDateFromClient" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>

            <b-col class="col-lg-4">
                <b-form>
                    <label for="law-matter-evidence-appeal-date-input">{{ $t('lawMatterEvidence.arrival_date_from_cerberus') }}</label>
                    <b-form-group>
                        <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('lawMatterEvidence.arrival_date_from_cerberus')" size="sm" v-model="arrivalDateFromCerberus" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="col-lg-12">
                <label for="law-matter-evidence-note-input">{{ $t('lawMatterEvidence.note') }}</label>
                <b-form-textarea size="sm" id="matter-note-input" v-model="note" max-rows="3" maxlength="250" />
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getLawMatterEvidenceList: {
            type: Function
        }
    },
    data() {
        return {
            id: 0,
            lawMatterId: 0,
            arrivalDateFromCerberus: null,
            arrivalDateFromClient: null,
            isImitationProduct: false,
            isOriginalProduct: false,
            note: "",
            purchaseDate: null,
        }
    },
    methods: {
        showInfo(id, lawMatterId) {
            this.$refs['lawMatterEvidenceFormModal'].show()
            this.id = id
            this.lawMatterId = lawMatterId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'LawMatterEvidence'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.arrivalDateFromCerberus = response.data.lawMatterEvidence.arrivalDateFromCerberus
                    this.arrivalDateFromClient = response.data.lawMatterEvidence.arrivalDateFromClient
                    this.purchaseDate = response.data.lawMatterEvidence.purchaseDate
                    this.isImitationProduct = response.data.lawMatterEvidence.isImitationProduct
                    this.isOriginalProduct = response.data.lawMatterEvidence.isOriginalProduct
                    this.note = response.data.lawMatterEvidence.note

                } else {
                    this.$bvModal.hide('lawMatterEvidenceFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                    this.clear()
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                lawMatterId: this.lawMatterId,
                arrivalDateFromCerberus: this.arrivalDateFromCerberus,
                arrivalDateFromClient: this.arrivalDateFromClient,
                isImitationProduct: this.isImitationProduct,
                isOriginalProduct: this.isOriginalProduct,
                note: this.note,
                purchaseDate: this.purchaseDate
            }
            const controller = {
                name: 'LawMatterEvidence'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$bvModal.hide('lawMatterEvidenceFormModal')
                    this.getLawMatterEvidenceList()
                    this.$SaveAlert()
                    this.clear()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                    this.clear()
                }
            })
        },
        clear() {
            this.arrivalDateFromCerberus = null,
                this.arrivalDateFromClient = null,
                this.isImitationProduct = false,
                this.isOriginalProduct = false,
                this.note = "",
                this.purchaseDate = null
        }
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
</style>
