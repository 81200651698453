<template>
<div>
    <b-card no-body class="card-statistics">
        <b-card-header>
            <b-card-title>{{ $t('dashboard.information_card') }}</b-card-title>
            <b-card-text class="mr-25 mb-0">
                {{ $t('dashboard.updated_info') + ' ' + this.date }}
            </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
            <b-row>
                <b-col md="3" sm="12" class="mb-2 mb-md-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar :to="{ path: '/ReminderReport' }" size="48" variant="light-primary">
                                <feather-icon size="24" icon="BellIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ reminderCount }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ $t('dashboard.reminder_count') }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col md="3" sm="12" class="mb-2 mb-md-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar :to="{ path: '/DailyworkReport', query: { lawBillingStatusId: 1 } }" size="48" variant="light-danger">
                                <feather-icon size="24" icon="BriefcaseIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ activeLawDailyworkCountForUser }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ $t('dashboard.dailywork') }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col md="3" sm="12" class="mb-2 mb-md-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar :to="{ path: '/LawClientPowerOfAttorneyDeadlineReport'}" size="48" variant="light-danger">
                                <feather-icon size="24" icon="AnchorIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ countOfLast3MonthsOfPowerOfAttorneyClients }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ $t('dashboard.countOfLast3MonthsOfPowerOfAttorneyClients') }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</div>
</template>

<script>
export default {
    data() {
        return {
            date: this.$formatFn(new Date()),
            reminderCount: 0,
            activeLawDailyworkCountForUser: 0,
            countOfLast3MonthsOfPowerOfAttorneyClients: 0
        }
    },
    beforeMount() {
        const data = {
            id: localStorage.getItem('EmployeeId')
        }
        const controller = {
            name: 'LawDashboard'
        }
        const payload = {
            data: data,
            controller: controller
        }
        this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
            if (response.data.resultStatus == true) {
                this.reminderCount = response.data.lawDashboard.reminderCount
                this.activeLawDailyworkCountForUser = response.data.lawDashboard.activeLawDailyworkCountForUser,
                    this.countOfLast3MonthsOfPowerOfAttorneyClients = response.data.lawDashboard.countOfLast3MonthsOfPowerOfAttorneyClients
            } else {
                this.$WarningAlert(response.data.resultMessage)
            }
        })
    }
}
</script>
  <!-- 

<style>
.custom-card {
    max-width: 600px;
    /* Kartın genişliğini artırın */
    margin: auto;
    /* Ortalamak için */
}
</style> -->
